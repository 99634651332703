





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import { getAPIConfiguration } from '@/clients/cpinblocks'
import FTMPOffers from '@/components/FTMPOffers.vue'
import type { Currency, TokenType } from '@/types'

@Component({
	components: {
		FTMPOffers,
	},
})
export default class OTC extends Vue {
	@Prop()
	productCurrency?: Currency

	@Prop()
	productType?: TokenType

	@Prop()
	unitPriceCurrency?: Currency

	@Prop()
	unitPriceType?: TokenType

	@Prop()
	seller?: string

	@Prop()
	buyer?: string

	@Prop()
	limit?: number

	@Prop()
	offset?: number

	@Prop()
	sort?: string

	conf: Conf | null = null
	currentBuyer: string | null = null
	currentSeller: string | null = null
	currentPair: string | null = null
	currentProductCurrency: Currency | null = null
	currentProductType: TokenType | null = null
	currentUnitPriceCurrency: Currency | null = null
	currentUnitPriceType: TokenType | null = null
	keyFTMPOffers = 1
	loading = true
	offerType = 'privateOffers'

	get currencies (): string[] {
		const result: string[] = []
		if (this.conf?.marketplaceFTPairs) {
			for (const p1 of this.conf?.marketplaceFTPairs) {
				for (const p2 of p1.pairs) {
					result.push(p1.currency + (p1.type !== 'MAIN' ? ' ' + p1.type : '') + ' / ' + p2.currency + ' ' + (p2.type !== 'MAIN' ? p2.type : ''))
				}
			}
		}
		result.sort()
		return result
	}

	async updatePath (): Promise<boolean> {
		const query = { } as any
		if (this.currentBuyer !== null) {
			query.buyer = this.currentBuyer
		}
		if (this.currentSeller !== null) {
			query.seller = this.currentSeller
		}
		if (this.currentProductCurrency !== null) {
			query.productCurrency = this.currentProductCurrency
		}
		if (this.currentProductType !== null) {
			query.productType = this.currentProductType
		}
		if (this.currentUnitPriceCurrency !== null) {
			query.unitPriceCurrency = this.currentUnitPriceCurrency
		}
		if (this.currentUnitPriceType !== null) {
			query.unitPriceType = this.currentUnitPriceType
		}
		let modified = false
		for (const k of Object.keys(query)) {
			if (query[k] !== this.$route.query[k]) {
				modified = true
			}
		}
		for (const k of Object.keys(this.$route.query)) {
			if (query[k] !== this.$route.query[k]) {
				modified = true
			}
		}
		if (modified) {
			await this.$router.replace({ query: query })
		}
		return modified
	}

	async onPairChanged (pair: string): Promise<void> {
		if (pair) {
			const p = pair.split('/')
			this.currentProductCurrency = p[0].trim().split(' ')[0].trim() as Currency
			if (p[0].trim().split(' ').length === 2) {
				this.currentProductType = p[0].trim().split(' ')[1].trim() as TokenType
			} else {
				this.currentProductType = 'MAIN' as TokenType
			}
			this.currentUnitPriceCurrency = p[1].trim().split(' ')[0].trim() as Currency
			if (p[1].trim().split(' ').length === 2) {
				this.currentUnitPriceType = p[1].trim().split(' ')[1].trim() as TokenType
			} else {
				this.currentUnitPriceType = 'MAIN' as TokenType
			}
		} else {
			this.currentProductCurrency = null
			this.currentProductType = null
			this.currentUnitPriceCurrency = null
			this.currentUnitPriceType = null
		}
		await this.refresh()
	}

	async onOfferTypeChanged (): Promise<void> {
		if (this.offerType === 'publicOffers') {
			this.currentBuyer = 'ALL'
			this.currentSeller = null
		} else if (this.$store.state.owner && this.offerType === 'privateOffers') {
			this.currentBuyer = this.$store.state.owner.ownerId
			this.currentSeller = null
		} else if (this.$store.state.owner && this.offerType === 'myOffers') {
			this.currentBuyer = null
			this.currentSeller = this.$store.state.owner.ownerId
		}
		await this.refresh()
	}

	private async mounted (): Promise<void> {
		if (this.buyer) {
			this.currentBuyer = this.buyer
		}
		if (this.seller) {
			this.currentSeller = this.seller
		}
		if (this.productCurrency) {
			this.currentProductCurrency = this.productCurrency
		}
		if (this.productType) {
			this.currentProductType = this.productType
		}
		if (this.unitPriceCurrency) {
			this.currentUnitPriceCurrency = this.unitPriceCurrency
		}
		if (this.unitPriceType) {
			this.currentUnitPriceType = this.unitPriceType
		}
		if (this.currentProductCurrency && this.currentProductType && this.unitPriceCurrency && this.unitPriceType) {
			this.currentPair = this.currentProductCurrency + (this.currentProductType !== 'MAIN' ? ' ' + this.currentProductType : '') + ' / ' + this.unitPriceCurrency + ' ' + (this.unitPriceType !== 'MAIN' ? this.unitPriceType : '')
		}
		await this.refresh()
		this.loading = false
	}

	async refresh (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		await this.updatePath()
		this.keyFTMPOffers++
		this.loading = false
	}
}
