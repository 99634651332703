
















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { createFTMPOffer, getAccounts, getAPIConfiguration, transferTokens } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Conf, MarketplaceFTPairConf } from '@/models/Conf'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError, convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import type { Currency, TokenType } from '@/types'
import { ValidationRules } from '@/types'
import { BigNumber } from 'bignumber.js'
import { FTMPOffer } from '@/models/FTMPOffer'
import { TranslateResult } from 'vue-i18n'

type OfferType = 'Buy' | 'Sell' | 'Send'

@Component({
	components: {
		CurrencyInput,
		Code2FADialog,
	},
})
export default class AddFTMPOffer extends Vue {
	@Prop({ default: () => [] }) currentPair!: Currency[]
	@Prop({ default: '0' }) currentPrice!: BigNumber
	@Prop({ default: '' }) offerType!: OfferType
	offer: FTMPOffer = {
		productAmount: new BigNumber('0'),
		unitPriceAmount: new BigNumber('0'),
	}

	show2FADialog = false
	conf: Conf | null = null
	quantitySoldPrecision = 0
	quantitySoldMax: BigNumber = new BigNumber('0')
	unitPricePrecision = 4
	sellerAccounts: Account[] = []
	creating = false
	loading = true
	minPrice: BigNumber = new BigNumber('0.0001')
	quantitySold = new BigNumber('0')
	sellerAccountIdentifier: string | null = null
	unitPrice = new BigNumber('0')
	unitPriceCurrency: Currency | null = null
	unitPriceType: TokenType | null = null
	totalPrice = new BigNumber('0')
	buyerId = ''
	splittable = true
	pair: MarketplaceFTPairConf | null = null
	currencies: Currency[] = []
	types: TokenType[] = []
	private isValid = false

	get preselectedValues (): Record<string, Currency> | null {
		const resObj = {
			buy: '' as Currency,
			sell: '' as Currency,
		}

		if (this.offerType.length && this.offerType === 'Buy') {
			resObj.buy = this.currentPair[0]
			resObj.sell = this.currentPair[1]
		} else if (this.offerType.length && this.offerType === 'Sell') {
			resObj.buy = this.currentPair[1]
			resObj.sell = this.currentPair[0]
		}

		return this.currentPair.length ? resObj : null
	}

	get hasPreselectedValues (): boolean {
		return !!this.preselectedValues
	}

	get isOfferTypeSend (): boolean {
		return this.offerType === 'Send'
	}

	get currencyQuantityInputLabel (): TranslateResult {
		return this.isOfferTypeSend
			? this.$t('addOffer.quantitySend', { currency: this.offer.productCurrency })
			: this.$t('addOffer.quantitySold', { currency: this.offer.productCurrency })
	}

	get modalTitle (): TranslateResult {
		return this.isOfferTypeSend
			? this.$t('ftmpAddOffer.transferTitle')
			: this.$t('ftmpOffer.title')
	}

	get ibexIdLabel (): TranslateResult {
		return this.isOfferTypeSend
			? this.$t('ftmpAddOffer.recipientId')
			: this.$t('ftmpAddOffer.buyerId')
	}

	get totalPricePrecision (): number {
		let result = this.unitPricePrecision + this.quantitySoldPrecision
		if (result > 18) {
			result = 18
		}
		return result
	}

	get unitPriceTypeNotOnlyMAIN (): boolean {
		if (this.types.length === 0) {
			return false
		}
		if (this.types.length > 1) {
			return true
		} else {
			return this.types[0] !== 'MAIN'
		}
	}

	private get rules (): ValidationRules {
		return {
			required: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
			],
			unitPrice: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo(this.minPrice) || this.$t('addOffer.rule.minUnitPrice', {
					currency: this.unitPriceCurrency,
					minPrice: this.minPrice,
				}),
			],
			quantitySold: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => convertValueToBigNumber(v).gt(0) || this.$t('ftmpOffer.rule.min', { min: 0 }),
				(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
				(v: string) => {
					return convertValueToBigNumber(v).isLessThanOrEqualTo(this.quantitySoldMax) || this.$t('ftmpOffer.rule.max', {
						max: formatFixedDecimals(this.$i18n, this.quantitySoldMax, this.quantitySoldPrecision),
					})
				},
			],
			buyerId: [
				(v?: string) => !!v || this.$t('rule.requiredField'),
				(v: string) => this.isOfferTypeSend
					? (!!v && v.length === 8) || this.$t('ftmpAddOffer.buyerIdLengthRequired')
					: !v || v.length === 8 || this.$t('ftmpAddOffer.buyerIdLength'),
			],
		}
	}

	onUpdateQuantitySold (n: BigNumber): void {
		this.quantitySold = n
		this.offer.productAmount = n
		this.totalPrice = this.quantitySold.multipliedBy(this.unitPrice)
		this.validateForm()
	}

	onUpdateUnitPrice (): void {
		this.offer.unitPriceAmount = this.unitPrice
		this.totalPrice = this.quantitySold.multipliedBy(this.unitPrice);
		(this.$refs.form as Vue & { validate: () => boolean }).validate()
	}

	async created (): Promise<void> {
		await this.reset()
		this.loading = false
	}

	async cancel (): Promise<void> {
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async createOffer (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true

		// If this modal window used to transfer tokens
		// Else If this modal window used to create a new offer on marketplace
		if (this.isOfferTypeSend) {
			try {
				const offerToTransfer = {
					currency: this.offer.productCurrency,
					type: this.offer.productType,
					amount: this.quantitySold,
					ownerIdTo: this.buyerId,
				}

				await transferTokens(this.$store.state.jwt, offerToTransfer, code2FA)
				this.creating = false
				this.$emit('success')
			} catch (error: any) {
				alertRawError(error.message)
				this.creating = false
				this.$emit('fail')
			}
		} else {
			try {
				this.offer.productAmount = this.quantitySold
				this.offer.unitPriceCurrency = this.unitPriceCurrency ? this.unitPriceCurrency : undefined
				this.offer.unitPriceType = this.unitPriceType ? this.unitPriceType : undefined
				this.offer.unitPriceAmount = this.unitPrice
				this.offer.splittable = this.splittable
				if (this.buyerId) {
					this.offer.buyer = this.buyerId
				}
				await createFTMPOffer(this.$store.state.jwt, this.offer, code2FA)
				this.creating = false
				this.$emit('success', this.offer)
			} catch (error: any) {
				alertRawError(error.message)
				this.creating = false
				this.$emit('fail')
			}
		}
	}

	onUpdateSellerAccountIdentifier (): void {
		if (this.offer) {
			for (const acc of this.sellerAccounts) {
				if (acc.id === this.sellerAccountIdentifier) {
					this.offer.productCurrency = acc.currency as Currency
					this.offer.productType = acc.type as TokenType
					this.quantitySoldMax = acc.balance
				}
			}
		}
		if (this.conf?.marketplaceFTPairs) {
			for (const p of this.conf.marketplaceFTPairs) {
				if (this.offer.productCurrency === p.currency && this.offer.productType === p.type) {
					this.pair = p
				}
			}
		}
		this.currencies = []
		if (this.pair?.pairs) {
			for (const p of this.pair.pairs) {
				this.currencies.push(p.currency)
			}
		}
		if (this.currencies.length === 1) {
			this.unitPriceCurrency = this.currencies[0]
		}
		this.onUpdateUnitPriceCurrency()
		this.validateForm()
	}

	onUpdateUnitPriceCurrency (): void {
		this.types = []
		if (this.pair?.pairs) {
			for (const p of this.pair.pairs) {
				if (p.currency === this.unitPriceCurrency) {
					this.types.push(p.type)
				}
			}
		}
		if (this.types.length === 1) {
			this.unitPriceType = this.types[0]
		}
	}

	async reset (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		this.sellerAccounts = []
		const myAccounts: Account[] = await getAccounts(this.$store.state.jwt, false)
		let icoAccountIdentifier: string | null = null

		// If this modal window used to transfer tokens
		// Else If this modal window used to create a new offer on marketplace
		if (this.isOfferTypeSend && this.conf?.transferable) {
			for (const a of myAccounts) {
				for (const pair of this.conf.transferable) {
					if (a.currency === pair.currency && a.type === pair.type) {
						a.name = a.currency + (a.type !== 'MAIN' ? ' ' + a.type : '') + ' (' + a.balance + ')'
						this.sellerAccounts.push(a)
					}
				}
			}
		} else if (this.conf?.marketplaceFTPairs) {
			for (const a of myAccounts) {
				for (const pair of this.conf.marketplaceFTPairs) {
					if (a.currency === pair.currency && a.type === pair.type) {
						a.name = a.currency + (a.type !== 'MAIN' ? ' ' + a.type : '') + ' (' + a.balance + ')'
						this.sellerAccounts.push(a)
						if (a.type === 'ICO') {
							icoAccountIdentifier = a.id
						}
					}
				}
			}
		}
		this.offer = {} as FTMPOffer
		this.onUpdateSellerAccountIdentifier()
		this.loading = false
		setTimeout(this.validateForm, 200)
	}

	validateForm (): void {
		if (!this.loading) {
			(this.$refs.form as Vue & { validate: () => boolean }).validate()
		}
	}

	onKeyPressBuyerID (e: KeyboardEvent): void {
		if (!e.key.match(/^[a-z0-9]$/)) {
			e.preventDefault()
		}
	}
}
