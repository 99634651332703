


































































































import { getAPIConfiguration, payFTMPOffer } from '@/clients/cpinblocks'
import { Conf } from '@/models/Conf'
import { FTMPOffer } from '@/models/FTMPOffer'
import { mdiInformation } from '@mdi/js'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import { ValidationRule } from '@/types'

@Component({
	components: {
		Code2FADialog,
		CurrencyInput,
	},
})
export default class FTMPBuy extends Vue {
	@Prop(Object) offer!: FTMPOffer
	@Prop() balance!: BigNumber

	amount = new BigNumber('0')
	mdiInformation = mdiInformation
	loading = true
	conf: Conf | null = null
	show2FADialog = false
	creating = false

	get productAmount (): BigNumber {
		return new BigNumber(this.offer.productAmount)
	}

	get unitPriceAmount (): BigNumber {
		return new BigNumber(this.offer.unitPriceAmount)
	}

	get totalPrice (): BigNumber {
		return new BigNumber(this.offer.unitPriceAmount).multipliedBy(this.amount)
	}

	get amountRules (): ValidationRule {
		const max = BigNumber.min(this.offer.productAmount, this.balance.dividedBy(this.offer.unitPriceAmount))
		return [
			(v?: string) => !!v || this.$t('rule.requiredField'),
			(v: string) => convertValueToBigNumber(v).isGreaterThan(0) || this.$t('ftmpBuy.rule.mustBeGT0'),
			(v: string) => !convertValueToBigNumber(v).isGreaterThan(max) || this.$t('ftmpBuy.rule.mustBeLessThanOrEqualsTo', { number: formatFixedDecimals(this.$i18n, max, 0) }),
		]
	}

	get totalPriceRules (): ValidationRule {
		BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
		return [
			(v: string) => !convertValueToBigNumber(v).isGreaterThan(this.balance) || this.$t('ftmpBuy.rule.mustBeLessThanOrEqualsTo', { number: formatFixedDecimals(this.$i18n, this.balance, 2) }),
		]
	}

	get canBuy (): boolean {
		return this.amount.gt(0) && this.totalPrice.lte(this.balance)
	}

	async buy (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		if (this.offer.id) {
			try {
				await payFTMPOffer(this.$store.state.jwt, this.offer.id, this.amount, code2FA)
				this.$emit('success')
			} catch (error) {
				this.$emit('fail')
			}
		}
	}

	cancel (): void {
		this.$emit('cancel')
	}

	async mounted (): Promise<void> {
		this.conf = await getAPIConfiguration()
		if (!this.offer.splittable) {
			this.amount = new BigNumber(this.offer.productAmount)
		}
		this.loading = false
	}

	created (): void {
		this.validateForm()
	}

	validateForm (): void {
		if (!this.loading) {
			(this.$refs.form as Vue & { validate: () => boolean }).validate()
		}
	}
}
