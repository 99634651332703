var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show2FADialog)?_c('Code2FADialog',{attrs:{"show":_vm.show2FADialog,"type":"weak","weak2FADuration":_vm.conf.weak2FADuration},on:{"validate":_vm.deleteOffer,"cancel":function($event){_vm.itemToDelete = null; _vm.show2FADialog = false; _vm.creating = false}}}):_vm._e(),_c('v-card',{attrs:{"elevation":"2"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(" Over The Counter ")])])],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.ftmpOffers,"sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"items-per-page":_vm.itemPerPage,"server-items-length":_vm.total,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100] },"loading":_vm.loading},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.onUpdateSortDesc],"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.onUpdateSortBy],"update:page":_vm.onUpdatePage,"update:items-per-page":_vm.onUpdateItemsPerPage},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.productCurrency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.productCurrency + (item.productType !== 'MAIN' ? ' ' + item.productType : ''))+" ")]}},{key:"item.unitPriceCurrency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unitPriceCurrency + (item.unitPriceType !== 'MAIN' ? ' ' + item.unitPriceType : ''))+" ")]}},{key:"item.splittable",fn:function(ref){
var item = ref.item;
return [(item.splittable)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.buyer",fn:function(ref){
var item = ref.item;
return [(item.buyer !== 'ALL')?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.visibleBuy(item))?_c('v-btn',{staticClass:"primary white--text",attrs:{"disabled":!_vm.canBuy(item),"readonly":""},on:{"click":function($event){return _vm.buy(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.buy'))+" ")]):_vm._e(),(_vm.visibleDelete(item))?_c('v-btn',{staticClass:"red white--text",attrs:{"disabled":!_vm.canDelete(item),"readonly":"","loading":_vm.itemToDelete === item.id},on:{"click":function($event){_vm.itemToDelete = item.id; _vm.show2FADialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]):_vm._e()]}}],null,true)})],1),(!_vm.loading)?_c('v-btn',{staticClass:"ma-4 primary white--text",attrs:{"fab":"","disabled":!_vm.enabledAdd},on:{"click":_vm.addOffer}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","width":"800px"},model:{value:(_vm.showAddFTMPOffer),callback:function ($$v) {_vm.showAddFTMPOffer=$$v},expression:"showAddFTMPOffer"}},[_c('AddFTMPOffer',{key:_vm.addId,on:{"fail":function($event){_vm.addId++; _vm.showAddFTMPOffer = false},"cancel":function($event){_vm.addId++; _vm.showAddFTMPOffer = false},"success":_vm.onSuccessAddFTMPOffer}})],1),_c('v-dialog',{attrs:{"scrollable":"","width":"800px"},model:{value:(_vm.showFTMPBuy),callback:function ($$v) {_vm.showFTMPBuy=$$v},expression:"showFTMPBuy"}},[(_vm.offer)?_c('FTMPBuy',{key:(new Date()).getTime(),attrs:{"offer":_vm.offer,"balance":_vm.getBalance(_vm.offer.unitPriceCurrency, _vm.offer.unitPriceType)},on:{"fail":function($event){_vm.showFTMPBuy = false},"cancel":function($event){_vm.showFTMPBuy = false},"success":_vm.onSuccessFTMPBuy}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }