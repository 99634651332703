








































































































import { deleteFTMPOffer, getAccounts, getAPIConfiguration, getFTMPOffer } from '@/clients/cpinblocks'
import { Account } from '@/models/Account'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import { FTMPOffer } from '@/models/FTMPOffer'
import { formatDate } from '@/utils'
import type { Currency, TokenType } from '@/types'
import BigNumber from 'bignumber.js'
import AddFTMPOffer from '@/components/AddFTMPOffer.vue'
import FTMPBuy from '@/components/FTMPBuy.vue'
import Code2FADialog from '@/components/Code2FADialog.vue'

@Component({
	components: {
		AddFTMPOffer,
		Code2FADialog,
		FTMPBuy,
	},
})
export default class FTMPOffers extends Vue {
	@Prop()
	buyer?: string

	@Prop()
	seller?: string

	@Prop()
	productCurrency?: Currency

	@Prop()
	productType?: TokenType

	@Prop()
	ownerId!: string

	@Prop()
	unitPriceCurrency?: Currency

	@Prop()
	unitPriceType?: TokenType

	headers = [
		{ align: 'left', sortable: true, text: this.$t('ftmpOfferTable.header.at'), value: 'createdAt' },
		{ align: 'right', sortable: false, text: this.$t('ftmpOfferTable.header.productAmount'), value: 'productAmount' },
		{ align: 'right', sortable: false, text: this.$t('ftmpOfferTable.header.productCurrency'), value: 'productCurrency' },
		{ align: 'right', sortable: true, text: this.$t('ftmpOfferTable.header.unitPriceAmount'), value: 'unitPriceAmount' },
		{ align: 'center', sortable: false, text: this.$t('ftmpOfferTable.header.unitPriceCurrency'), value: 'unitPriceCurrency' },
		{ align: 'center', sortable: false, text: this.$t('ftmpOfferTable.header.splittable'), value: 'splittable' },
		{ align: 'center', sortable: false, text: this.$t('ftmpOfferTable.header.actions'), value: 'actions' },
	]

	accounts: Account[] | null = null
	addId = 0
	conf: Conf | null = null
	ftmpOffers: FTMPOffer[] = []
	itemPerPage = 10
	itemToDelete: string | null = null
	loading = true
	offer: FTMPOffer | null = null
	page = 1
	show2FADialog = false
	showAddFTMPOffer = false
	showFTMPBuy = false
	total = 0
	sortBy = 'unitPriceAmount'
	sortDesc = false

	async addOffer (): Promise<void> {
		this.showAddFTMPOffer = true
	}

	async buy (offer: FTMPOffer): Promise<void> {
		this.offer = offer
		this.showFTMPBuy = true
	}

	get enabledAdd (): boolean {
	  if (!this.$store.getters.kyc2fa) {
		  return false
	  }
	  if (this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD') {
			return false
		}
		return true
	}

	canBuy (offer: FTMPOffer): boolean {
		if (!this.$store.getters.kyc2fa) {
			return false
		}
		if (this.$store.state.owner.locked === 'SOFT' || this.$store.state.owner.locked === 'HARD') {
			return false
		}
		if (offer.splittable) {
			return this.getBalance(offer.unitPriceCurrency, offer.unitPriceType).gt(0)
		} else {
			return this.getBalance(offer.unitPriceCurrency, offer.unitPriceType).gt(new BigNumber(offer.unitPriceAmount).multipliedBy(offer.productAmount))
		}
	}

	visibleBuy (offer: FTMPOffer): boolean {
		return offer.seller !== this.ownerId
	}

	canDelete (offer: FTMPOffer): boolean {
	  if (this.$store.state.owner.locked === 'HARD') {
		  return false
	  }
		// TODO : replace this with ownerId once ready
		return offer.seller === this.ownerId
	}

	visibleDelete (offer: FTMPOffer): boolean {
		// TODO : replace this with ownerId once ready
		return offer.seller === this.ownerId
	}

	async mounted (): Promise<void> {
		await this.refresh()
	}

	async refresh (): Promise<void> {
		this.loading = true
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.conf = await getAPIConfiguration()
		await this.refreshOffers()
		this.loading = false
	}

	async refreshOffers (): Promise<void> {
		this.loading = true
		let sort
		if (this.sortBy !== null && this.sortDesc !== null) {
			sort = this.sortBy + ':' + (this.sortDesc ? 'DESC' : 'ASC')
		}
		const result = await getFTMPOffer(this.$store.state.jwt, {
			buyer: this.buyer,
			seller: this.seller,
			offset: (this.page - 1) * this.itemPerPage,
			productCurrency: this.productCurrency,
			productType: this.productType,
			limit: this.itemPerPage,
			sort: sort,
			unitPriceCurrency: this.unitPriceCurrency,
			unitPriceType: this.unitPriceType,
		})
		if (result) {
			this.ftmpOffers = result.results ? result.results : []
			this.total = result.total
		}
		this.loading = false
	}

	private formatDate (date: Date): string {
		return formatDate(date, this.$i18n)
	}

	private getBalance (currency: Currency | undefined, type: TokenType | undefined): BigNumber {
		if (this.accounts && currency && type) {
			for (const acc of this.accounts) {
				if (acc.currency === currency && acc.type === type) {
					return acc.balance
				}
			}
		}
		return new BigNumber('0')
	}

	async onSuccessAddFTMPOffer (): Promise<void> {
		this.addId++
		this.showAddFTMPOffer = false
		await this.refresh()
	}

	async onSuccessFTMPBuy (): Promise<void> {
		this.showFTMPBuy = false
		await this.refresh()
	}

	async deleteOffer (object: any): Promise<void> {
		this.show2FADialog = false
		const	code2FA = object.code
		if (this.itemToDelete) {
			await deleteFTMPOffer(this.$store.state.jwt, this.itemToDelete, code2FA)
		}
		await this.refresh()
		this.itemToDelete = null
	}

	async onUpdateItemsPerPage (n: number): Promise<void> {
		this.itemPerPage = n
		await this.refreshOffers()
	}

	async onUpdatePage (page: number): Promise<void> {
		this.page = page
		await this.refreshOffers()
	}

	async onUpdateSortBy (name: string | undefined): Promise<void> {
		if (name === undefined) {
			this.sortBy = 'createdAt'
			this.sortDesc = false
		} else {
			this.sortBy = name
		}
		await this.refreshOffers()
	}

	async onUpdateSortDesc (desc: boolean | undefined): Promise<void> {
		if (desc === undefined) {
			this.sortDesc = false
		} else {
			this.sortDesc = desc
		}
		await this.refreshOffers()
	}
}
